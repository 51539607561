<template>
    <section id="pricing" class="section pricing-two bg-img">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-6">
                    <div class="section__header wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <h2 class="h2">{{ labelsState['ready_to_start'] || '¿Ready to start?' }}</h2>
                        <h2 class="h2">{{ labelsState['start_today_free'] || 'Start today totally free' }}</h2>
                    </div>
                </div>
            </div>
            <div class="row items-gap">
                <div 
                    class="col-12 col-md-6 col-lg-6 col-xxl-3" 
                    v-for="plan in plans" 
                    :key="plan.id"
                >
                    <div class="pricing-two__single wow fadeInUp" data-wow-duration="600ms" data-wow-delay="900ms">
                        <h5 class="h5 yel">{{ plan.subscription_plan }}</h5>
                        <div class="meta text-center">
                            <div class="content">
                                <h2 class="price">{{ plan.amount ? '$' + plan.amount : 'Free' }}</h2>
                                <h2 class="price">{{ plan.amount_cop ? '$' + plan.amount_cop: 'Free' }} <sup class="mt-4 cop-super">COP</sup></h2>
                            </div>
                            <p>{{ plan.frequency }} {{ labelsState['month_s'] || 'Month(s)' }}</p>
                        </div>
                        <hr>
                        <ul>
                            <li><i class="fa-solid fa-check"></i> {{ labelsState['tokens_texto'] || 'Text Tokens' }}: {{ plan.tokens_texto }}</li>
                            <li><i class="fa-solid fa-check"></i> {{ labelsState['tokens_audio'] || 'Audio Tokens' }}: {{ plan.tokens_audio }}</li>
                            <li><i class="fa-solid fa-check"></i> {{ labelsState['tokens_video'] || 'Video Tokens' }}: {{ plan.tokens_video }}</li>
                            <li><i class="fa-solid fa-check"></i> {{ labelsState['tokens_image'] || 'Image Tokens' }}: {{ plan.tokens_image }}</li>
                        </ul>
                        <hr>
                            <p>{{ plan.description }}</p>
                        <hr>
                        <a 
                            :href="subsUrl"
                            target="_blank" 
                            class="btn btn--septenary"
                        >
                            {{ plan.is_free ? (labelsState['free_demo'] || 'Free Demo') : (labelsState['suscribe_now'] || 'Subscribe Now') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';

export default {
    name: "Pricing",
    setup() {
        const subsUrl = `${process.env.VUE_APP_DASHBOARD_URL}/subscriptions`;
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();
        const plans = ref([]);
        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${process.env.VUE_APP_HOST_BILLING}/plans_open`);
                plans.value = response.data.plans;
            } catch (error) {
                console.error("Error fetching plans:", error);
            }
        };

        onMounted(() => {
            fetchPlans();
        });

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);

        return {
            labelsState,
            languageCodes,
            plans,
            subsUrl,
        };
    },
};
</script>

<style>
.pricing-two {
    background-color: #111827;
}

.text-center {
    text-align: center;
}

.price {
    font-size: 2rem;
    margin: 0.5rem 0;
    font-weight: bold;
    color: white;
}

.frequency {
    font-size: 1.2rem;
    color: white;
    display: block;
    margin-bottom: 1rem;
}

.meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.price {
    font-size: 2rem;
    margin: 0.5rem 0;
    font-weight: bold;
    color: white;
    text-align: center;
}

.cop-super {
    font-size: 0.6em;
    vertical-align: super;
    color: white;
}

.meta .content {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

ul {
    list-style: none;
    padding: 0;
    color: white;
}

ul li {
    margin: 0.5rem 0;
}

h2.price, small.frequency {
    display: block;
    text-align: center;
    margin: 0 auto;
}

.pricing-two__single {
    padding: 20px;
    background-color: #1f2937;
    border-radius: 10px;
}
</style>
