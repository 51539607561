import { defineStore } from 'pinia';

export const useLanguageStore = defineStore('language', {
  state: () => ({
    codes: JSON.parse(localStorage.getItem('languageCodes') || '[]')
  }),
  actions: {
    setCodes(newCodes) {
      this.codes = newCodes;
      localStorage.setItem('languageCodes', JSON.stringify(newCodes));
    },
    clearCodes() {
      this.codes = [];
      localStorage.removeItem('languageCodes');
    }
  }
});

