<template>
    <div>
        <div class="my-app home-two-dark">
			<Header2 class="header--dark" />
			<MobileMenu />
            <section class="section team-main pb-0">
                <div class="container">                    
                </div>
                <div class="row items-gap align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="team-main-single wow fadeInLeft" data-wow-duration="600ms" data-wow-delay="300ms">
                        <!--<img :src="require('@/assets/images/2. Confuse Bot.png')" alt="Image">-->
                    
                        <h1 class="h1">{{ labelsState['terms_and_conditions'] || 'Terms and Conditions' }}</h1>

                        <h1 class="h2">{{ labelsState['introduction'] || 'Introduction' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['introduction_text_three'] || 'These terms and conditions regulate the use of the NQA chatbot service provided by Neuro Quantum Artificial SAS. By accessing and using the NQA Chatbot, the user agrees to comply with these terms. If the user does not agree with these terms, they must refrain from using the Chatbot and other services of Neuro Quantum Artificial.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['definition_purpose_chatbot'] || 'Definition and Purpose of the Chatbot' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['definition_purpose_chatbot_text'] || 'The chatbot is an automated tool designed to interact with users and provide information, assistance, and other services related to the products and services offered by the company and the user. The chatbot can be accessed through various platforms, including but not limited to websites, mobile apps, and messaging platforms. Clients will not receive or have access to the underlying code or software of the service and will not receive a copy of the software itself.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['use_nqa_chatbot'] || 'Use of the NQA Chatbot' }}</h1>
                        <h1 class="h3">{{ labelsState['access_acceptable_use'] || 'Access and Acceptable Use' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['access_acceptable_use_text'] || 'Access to the chatbot is available to any user with access to the platforms after the subscription is completed by the user, taking into account the corresponding roles. The user agrees to use the NQA chatbot responsibly and in accordance with applicable law, these terms, and any other relevant regulations.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['prohibitions'] || 'Prohibitions' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['prohibitions_text'] || 'The user shall not: Use the chatbot for any illegal or unauthorized purpose. Introduce viruses, malware, or any other harmful code. Transmit information that is defamatory, offensive, or violates the rights of third parties. Perform any action that may compromise the security or functionality of the chatbot.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['client_responsibilities'] || 'Client Responsibilities' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['client_responsibilities_text'] || 'The client is responsible for all activities performed on their account and the accounts of their users, except when such activities result from unauthorized access due to service failures. The client must ensure that their users are aware of the obligations and restrictions of this agreement and comply with them, assuming responsibility for any violation committed by a user.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['use_restrictions'] || 'Use Restrictions' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['use_restrictions_text'] || 'The client agrees not to allow users or third parties to, directly or indirectly: alter, translate, copy, or create derivative works from the service; reverse engineer, decompile, or attempt to discover the source code or underlying ideas of the service; sublicense, sell, rent, lease, distribute, or commercially exploit the service in any other way; remove proprietary notices from the service; use the service in violation of laws or regulations; attempt to access or disrupt the service without authorization; use the service to support competitive products of NQA chatbot; test the vulnerability of the service without authorization.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['api_access_restrictions'] || 'API Access Restrictions' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['api_access_restrictions_text'] || 'NQA chatbot may provide access to APIs as part of the service. NQA chatbot reserves the right to establish and enforce usage limits for the APIs, and the client agrees to comply with such limits. NQA chatbot may also suspend or terminate API access at any time.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['contract_term_renewals'] || 'Contract Term and Renewals' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['contract_term_renewals_text'] || 'Subscriptions to access and use the NQA chatbot service begin on the specified subscription start date and remain active during the subscription period. Clients may choose not to renew their subscription by notifying NQA chatbot through the email provided. If this agreement is terminated by either party, all orders will automatically be canceled. If a client cancels or decides not to renew their paid subscription, the client’s subscription will automatically downgrade to a free version with limited features.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['payment_failure'] || 'Payment Failure' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['payment_failure_text'] || 'If a client fails to pay any fees by the due date, NQA chatbot may suspend access to the service until outstanding amounts are paid. NQA chatbot is authorized to attempt to charge the client’s payment method multiple times if the initial attempt fails. If a client believes they have been incorrectly billed, they must contact NQA chatbot within sixty (60) days of the statement date showing the error to request an adjustment.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['warranty_disclaimer'] || 'Warranty Disclaimer' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['warranty_disclaimer_text'] || 'Unless expressly stated herein, the services and all related components and information are provided "as is" and "as available" without any warranty of any kind. NQA chatbot expressly disclaims all warranties, whether express or implied, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['privacy_data_protection'] || 'Privacy and Data Protection' }}</h1>
                        <h1 class="h3">{{ labelsState['data_collection'] || 'Data Collection' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['data_collection_text'] || 'The chatbot may collect and process personal data from the user in order to provide and improve services. Data collected may include but is not limited to name, email address, phone number, company name, and any other information provided by the user during interactions with the NQA chatbot.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['data_usage'] || 'Data Usage' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['data_usage_text'] || 'The company commits to using the user is personal data in accordance with applicable data protection regulations, including but not limited to the European Union is GDPR and Colombia isPersonal Data Protection Law.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['service_data'] || 'Service Data' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['service_data_text'] || 'NQA chatbot collects data on the performance and operation of the service as clients use it. Service data is aggregated and anonymized, without revealing any personal information. NQA chatbot may freely use this data.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['user_rights'] || 'User Rights' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['user_rights_text_two'] || 'The user has the right to access, rectify, delete, and object to the processing of their personal data. To exercise these rights, the user may contact the company through the channels provided in the company is privacy policy.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['liability_limitation'] || 'Liability Limitation' }}</h1>
                        <h1 class="h3">{{ labelsState['accuracy_information'] || 'Accuracy of Information' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['accuracy_information_text'] || 'The company does not guarantee that the information provided by the chatbot is complete, accurate, or up-to-date. The user agrees that the use of the chatbot is at their own risk.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['damages'] || 'Damages' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['damages_text'] || 'In no event will the company be liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from the use or inability to use the NQA chatbot.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['intellectual_property'] || 'Intellectual Property' }}</h1>
                        <h1 class="h3">{{ labelsState['copyright_trademarks'] || 'Copyright and Trademarks' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['copyright_trademarks_text'] || 'All intellectual property rights related to the NQA chatbot, including but not limited to software, texts, images, and any other content, are the exclusive property of the company or its licensors.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['license_use'] || 'License to Use' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['license_use_text'] || 'The company grants the user a limited, non-exclusive, non-transferable, and revocable license to use the NQA chatbot in accordance with these terms.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['modifications'] || 'Modifications' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['modifications_text'] || 'The company reserves the right to modify these terms at any time. Modifications will take effect from the moment they are published on the platforms where the NQA chatbot is available. Continued use of the chatbot after the modification of the terms will constitute acceptance of such modifications by the user.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['termination'] || 'Termination' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['termination_text'] || 'The company may, at its sole discretion, suspend or terminate the user’s access to the chatbot at any time and for any reason, including but not limited to breach of these terms.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['applicable_law_jurisdiction'] || 'Applicable Law and Jurisdiction' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['applicable_law_jurisdiction_text'] || 'These terms will be governed and interpreted in accordance with the laws of Colombia or other countries where NQA services are provided, without prejudice to the rights that may correspond to users under the legislation of their place of residence.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['contact'] || 'Contact' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['contact_text'] || 'For any questions or complaints related to the use of the NQA chatbot, the user may contact the company at contacto@nqartificial.com or +57 3027506799.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['terms_acceptance'] || 'Acceptance of the Terms' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['terms_acceptance_text'] || 'The use of the NQA chatbot implies full and unconditional acceptance of these terms by the user. If the user does not agree with any of the terms established herein, they must refrain from using the NQA chatbot.' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer2 />
        </div>
        <BackToTop />
    </div>
</template>

<script>
    import Header2 from '../components/layout/Header2.vue'
	import MobileMenu from '../components/layout/MobileMenu.vue'
    import Footer2 from '../components/layout/Footer2.vue'
    import BackToTop from '../components/common/BackToTop.vue'
    import { useLabelsStore } from '@/stores/labels';
    import { useLanguageStore } from '@/stores/language';
    import { computed, onMounted } from 'vue';
    import axios from 'axios';

    export default {
        name: "UseCase",
        components: {
            Header2,
			MobileMenu,
            Footer2,
            BackToTop
        },
        name: "UseCase",
            setup() {
                const labelsStore = useLabelsStore();
                const languageStore = useLanguageStore();

                const changeLanguage = (languageCode) => {
                    labelsStore.fetchAndSetLabels(languageCode);
                };

                const labelsState = computed(() => labelsStore.labels);
                const languageCodes = computed(() => languageStore.codes);

                return {
                    labelsState,
                    languageCodes,
                    changeLanguage,
                };
            },
    };
</script>