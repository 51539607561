<template>
    <section class="section banner-two">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-9">
                    <div class="banner-two__content wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <h1 class="h1">{{ labelsState['banner_title'] || 'The best Artificial Intelligence tool for your business.' }}</h1>
                        <h2 class="h1">
                            <span id="textTyped">Neuro Quantum Artificial</span>
                        </h2>
                        <p class="text-lg">{{ labelsState['create_yout_chat'] || 'Create Your Chatbot in Just 3 Minutes – Automate and Increase Your Sales Today!' }}</p>
                        <div class="section__content-cta">
                            <a :href="registerUrl" target="_blank" class="btn btn--senary">{{ labelsState['start_free_now'] || 'Start Free Now' }}</a>                               
                            <p>{{ labelsState['get_free_demo'] || 'Create your chatbot in 3 minutes, totally free.' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="anime">
            <img :src="require('@/assets/images/banner/banner-robot.png')" alt="Image" class="one">            
        </div>
        
    </section>
</template>

<script>
import Typed from 'typed.js';
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted, watch, ref } from 'vue';
import axios from 'axios';

export default {
    name: "Banner",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);
        const typedInstance = ref(null);
        const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;


        const initTyped = () => {
            if (typedInstance.value) {
                typedInstance.value.destroy();
            }
            const options = {
                strings: [
                    labelsState.value['neuro_quantum_artificial'] || "Neuro Quantum Artificial",
                    labelsState.value['chat_bot'] || "Chat bot",
                    labelsState.value['artificial_intelligence'] || "Artificial intelligence",
                    labelsState.value['the_chat_revolution'] || "The Chatbot Revolution"
                ],
                typeSpeed: 50,
                startDelay: 50,
                backSpeed: 50,
                backDelay: 1000,
                loop: true,
            };
            typedInstance.value = new Typed("#textTyped", options);
        };

        watch(labelsState, (newVal, oldVal) => {
            if (Object.keys(newVal).length) {
                initTyped();
            }
        }, { immediate: true });

        return {
            labelsState,
            languageCodes,
            changeLanguage,
            registerUrl,
        };
    },
};
</script>

<style scoped>
    .banner-two {
        background-color: #111827;
    }
</style>
