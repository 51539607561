<template>
    <div>
        <div class="my-app home-two-dark">
            <Header2 class="header--dark" />
			<MobileMenu />
            <Header :logo-src="require('@/assets/images/logo.png')" />
            <PageHeader page-title="Create Account"  />
            <section class="section authentication pb-0">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-6">
                            <div class="authentication__inner wow fadeInUp" data-wow-duration="600ms"
                                data-wow-delay="300ms">
                                <div class="section__header text-start">
                                    <h2 class="h3">Crear cuenta</h2>
                                </div>
                                <form action="#" method="post">
                                    <div class="input-single">
                                        <input type="text" name="author-reginame" id="authorregiName"
                                            placeholder="Nombre completo" required>
                                    </div>
                                    <div class="input-single">
                                        <input type="email" name="author-regiEmail" id="authorregiEmail"
                                            placeholder="Correo electronico" required>
                                    </div>
                                    <div class="input-single">
                                        <input type="password" name="author-regiPass" id="authorregiPass"
                                            placeholder="Contraseña" required>
                                    </div>
                                    <div class="input-single">
                                        <input type="password" name="author-regiConfirmPass" id="authorregiConfirmPass"
                                            placeholder="Confirmar contraseña" required>
                                    </div>
                                    <div class="divide">
                                        <p>O </p>
                                    </div>
                                    <div class="authentic">
                                        <button type="submit" class="btn btn--nonary">
                                            <img :src="require('@/assets/images/google.png')" alt="Image">
                                            Continua con google
                                        </button>
                                        <button type="submit" class="btn btn--nonary">
                                            <img :src="require('@/assets/images/facebook.png')" alt="Image">
                                            Continua con facebook
                                        </button>
                                    </div>
                                    <div class="group-radio">
                                        <input type="checkbox" name="create-in-check" id="createInCheck">
                                        <label for="createInCheck">
                                          Acepto terminos y condiciones
                                        </label>
                                    </div>
                                    <div class="form-cta">
                                        <button type="submit" aria-label="post comment" class="btn btn--ocotonary">
                                            Crear cuenta
                                        </button>
                                        <p>
                                            ¿Ya tienes cuenta?
                                            <router-link to="/sign-in">Ingresar</router-link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer2 />
        </div>
        <BackToTop />
    </div>
</template>

<script>
    import Header2 from '../components/layout/Header2.vue'
	import MobileMenu from '../components/layout/MobileMenu.vue'
    //import PageHeader from '../components/layout/PageHeader.vue'
    import Footer2 from '../components/layout/Footer2.vue'
    import BackToTop from '../components/common/BackToTop.vue'
    export default {
        name: "Register",
        components: {
            Header2,
			MobileMenu,
            //PageHeader,
            Footer2,
            BackToTop
        },
    };
</script>