import { defineStore } from 'pinia';
import axios from 'axios';

export const useLabelsStore = defineStore('labels', {
  state: () => ({
    labels: JSON.parse(localStorage.getItem('labels') || '{}')
  }),
  actions: {
    async fetchAndSetLabels(languageCode) {
      try {
        const apiUrl = `${process.env.VUE_APP_HOST_MANAGEMENT}/languages/${languageCode}`;
        console.log("Fetching labels from URL:", apiUrl);
        const response = await axios.get(apiUrl, {
          withCredentials: true,
        });
        const { text } = response.data;

        this.labels = text;

        // Guardar en localStorage
        localStorage.setItem('labels', JSON.stringify(text));
      } catch (error) {
        console.error("Error fetching and setting labels:", error);
      }
    },
    setLabel(key, value) {
      this.labels[key] = value;
      localStorage.setItem('labels', JSON.stringify(this.labels));
    },
    removeLabel(key) {
      delete this.labels[key];
      localStorage.setItem('labels', JSON.stringify(this.labels));
    },
    clearLabels() {
      this.labels = {};
      localStorage.removeItem('labels');
    }
  }
});
