import Vue from 'vue';
import WOW from 'wowjs';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import App from './App.vue';
import router from './router';
import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

new Vue({
  el: '#app',
  router,
  pinia,
  mounted() {
    new WOW.WOW().init();
  },
  render: h => h(App)
});
  