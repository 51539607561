var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-app home-two-dark"},[_c('Header2',{staticClass:"header--dark"}),_c('MobileMenu'),_c('Header',{attrs:{"logo-src":require('@/assets/images/logo.png')}}),_c('PageHeader',{attrs:{"page-title":"Create Account"}}),_c('section',{staticClass:"section authentication pb-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-lg-10 col-xl-6"},[_c('div',{staticClass:"authentication__inner wow fadeInUp",attrs:{"data-wow-duration":"600ms","data-wow-delay":"300ms"}},[_vm._m(0),_c('form',{attrs:{"action":"#","method":"post"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"authentic"},[_c('button',{staticClass:"btn btn--nonary",attrs:{"type":"submit"}},[_c('img',{attrs:{"src":require('@/assets/images/google.png'),"alt":"Image"}}),_vm._v(" Continua con google ")]),_c('button',{staticClass:"btn btn--nonary",attrs:{"type":"submit"}},[_c('img',{attrs:{"src":require('@/assets/images/facebook.png'),"alt":"Image"}}),_vm._v(" Continua con facebook ")])]),_vm._m(6),_c('div',{staticClass:"form-cta"},[_c('button',{staticClass:"btn btn--ocotonary",attrs:{"type":"submit","aria-label":"post comment"}},[_vm._v(" Crear cuenta ")]),_c('p',[_vm._v(" ¿Ya tienes cuenta? "),_c('router-link',{attrs:{"to":"/sign-in"}},[_vm._v("Ingresar")])],1)])])])])])])]),_c('Footer2')],1),_c('BackToTop')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__header text-start"},[_c('h2',{staticClass:"h3"},[_vm._v("Crear cuenta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-single"},[_c('input',{attrs:{"type":"text","name":"author-reginame","id":"authorregiName","placeholder":"Nombre completo","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-single"},[_c('input',{attrs:{"type":"email","name":"author-regiEmail","id":"authorregiEmail","placeholder":"Correo electronico","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-single"},[_c('input',{attrs:{"type":"password","name":"author-regiPass","id":"authorregiPass","placeholder":"Contraseña","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-single"},[_c('input',{attrs:{"type":"password","name":"author-regiConfirmPass","id":"authorregiConfirmPass","placeholder":"Confirmar contraseña","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divide"},[_c('p',[_vm._v("O ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-radio"},[_c('input',{attrs:{"type":"checkbox","name":"create-in-check","id":"createInCheck"}}),_c('label',{attrs:{"for":"createInCheck"}},[_vm._v(" Acepto terminos y condiciones ")])])
}]

export { render, staticRenderFns }