<template>
    <footer class="footer-two section bg-img">
        <div class="container">
            <div class="row items-gap-two">
                <div class="col-12 col-sm-6 col-xl-4 col-xxl-5">
                    <div class="footer-two__single bt wow fadeInUp" data-wow-duration="600ms">
                        <router-link to="/" class="logo">
                            <img :src="require('@/assets/images/LOGO NEGATIVO SIN FONDO.png')" alt="Image">
                        </router-link>
                        <p>
                            Copyright &copy;
                            <span id="copyYear"></span>
                            <router-link to="/">Neuro Quantum Artificial</router-link>
                            {{ labelsState['design_by'] || '. Design By' }}
                            <a href="https://www.instagram.com/nqartificial?igsh=bGs1bzd2dTQ1ZHA1">NQA</a>
                        </p>
                        <div class="social">
                            <a href="https://www.facebook.com/people/Neuro-Quantum-Artificial/61558761272414/" aria-label="social media" target="_blank">
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                            <a href="https://x.com/NQArtificial" aria-label="social media" target="_blank">
                                <i class="fa-brands fa-twitter"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/nqartificial/" aria-label="social media" target="_blank">
                                <i class="fa-brands fa-linkedin-in"></i>
                            </a>
                            <a href="https://www.instagram.com/nqartificial/?igsh=bGs1bzd2dTQ1ZHA1" aria-label="social media" target="_blank">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-xl-2 col-xxl-2">
                    <div class="footer-two__single wow fadeInUp" data-wow-duration="600ms" data-wow-delay="200ms">
                        <router-link to="/"></router-link>
                        <h5 class="h5">{{ labelsState['about_nq_artificial'] || 'About NQ Artificial' }}</h5>
                        <ul>
                            <li><a :href="loginUrl" target="_blank">{{ labelsState['login'] || 'Login' }}</a></li>
                            <li><a :href="registerUrl" target="_blank">{{ labelsState['register'] || 'Register' }}</a></li>
                            <li><router-link to="/contact-us">{{ labelsState['contact_us'] || 'Contact Us' }}</router-link></li>
                            <li><router-link to="/TermsAndConditions">{{ labelsState['terms_and_conditions'] || 'Terms and Conditions' }}</router-link></li>
                            <li><router-link to="/Policies">{{ labelsState['policies'] || 'Policies' }}</router-link></li>
                            <li><router-link to="/DataTreatment">{{ labelsState['data_treatment'] || 'Data Treatment' }}</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-xl-4 col-xxl-3">                    
                    <div class="anime">
                        <img :src="require('@/assets/images/banner/footer-robot.png')" alt="Image" class="one">                            
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, reactive, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: "Footer2",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);
        const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;
        const loginUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-login?redirect=/`

        const state = reactive({
            scrollPosition: null,
        });

        return {
            labelsState,
            languageCodes,
            changeLanguage,
            registerUrl,
            loginUrl,
        };
    },
    mounted() {
        document.getElementById('copyYear').textContent = new Date().getFullYear();
    }
};
</script>


<style>
.footer-two {
    background-image: url('~@/assets/images/home-five-footer.png');
}

.home-two-light .footer-two {
    background-image: url('~@/assets/images/footer-two-bg-light.png');
}
</style>

