<template>
    <section class="section pb-0 testimonial">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section__header wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <h4 class="h2">{{ labelsState['clients_testimonials'] || 'Clients and testimonials.' }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="testimonial__slider-wrapper container">
            <div class="testimonial__slider">
                <div class="swiper-wrapper">
                    <div class="review__single swiper-slide">
                        <div class="review-head">
                            <div class="review__icons">
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                            </div>
                            <img :src="require('@/assets/images/generate/custom-chat.png')" alt="Image">
                        </div>
                        <div class="review-content">
                            <p class="fw-7">
                                {{ labelsState['review_exterium'] || 'Excellent work team, they helped me create my website, logo and image identity, in addition to incorporating the chatbot into my website and now I can respond much faster to my client is messages.' }}
                            </p>
                        </div>
                        <div class="review-meta">
                            <div class="thumb">
                                <img :src="require('@/assets/images/generate/custom-chat.png')" alt="Image">    
                            </div>
                            <div class="content">
                                <p class="h6">Exterium SAS</p>
                                <p>CEO, Exterium</p>
                            </div>
                        </div>
                    </div>
                    <div class="review__single swiper-slide">
                        <div class="review-head">
                            <div class="review__icons">
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                            </div>
                            <img :src="require('@/assets/images/generate/custom-chat.png')" alt="Image">
                        </div>
                        <div class="review-content">
                            <p class="fw-7">
                                {{ labelsState['rewie_muisk'] || 'Thanks to the NQA chatbot, the company was able to improve customer service, responding to any concerns in less time, increasing sales. NQA really is an excellent virtual advisor.' }}
                            </p>
                        </div>
                        <div class="review-meta">
                            <div class="thumb">
                            <img :src="require('@/assets/images/generate/custom-chat.png')" alt="Image"> 
                            </div>
                            <div class="content">
                                <p class="h6">Muisk Colombia</p>
                                <p>Admin, Muisk Colombia</p>
                            </div>
                        </div>
                    </div>     
                    <div class="review__single swiper-slide">
                        <div class="review-head">
                            <div class="review__icons">
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                            </div>
                            <img :src="require('@/assets/images/generate/custom-chat.png')" alt="Image">
                        </div>
                        <div class="review-content">
                            <p class="fw-7">
                                {{ labelsState['review_pijamas'] || 'Excellent service.' }}
                            </p>
                        </div>
                        <div class="review-meta">
                            <div class="thumb">
                                <img :src="require('@/assets/images/generate/custom-chat.png')" alt="Image">    
                            </div>
                            <div class="content">
                                <p class="h6">Pijamas SA</p>
                                <p>Miguel, Pijamas SA</p>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
            <div class="testimonial__arrows">
                <button aria-label="previous slide" class="slide-btn prev-testimonial">
                    <i class="tech-left-angle"></i>
                </button>
                <button aria-label="next slide" class="slide-btn next-testimonial">
                    <i class="tech-right-angle"></i>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
    import Swiper from 'swiper';
    import 'swiper/swiper-bundle.css';
    import { useLabelsStore } from '@/stores/labels';
    import { useLanguageStore } from '@/stores/language';
    import { computed, onMounted } from 'vue';
    import axios from 'axios';


    export default {
        name: "Testimonials",
        mounted() {
            new Swiper('.testimonial__slider', {
                slidesPerView: 'auto',
                centeredSlides: true,
                slidesPerGroup: 1,
                spaceBetween: 30,
                loop: true,
                speed: 1000,
                autoplay: {
                    delay: 4000,
                },
                navigation: {
                    nextEl: '.next-testimonial',
                    prevEl: '.prev-testimonial',
                },
                breakpoints: {
            
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            
            1200: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        }
            });
        },

        setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);

        return {
            labelsState,
            languageCodes,
            changeLanguage,
        };
    },
    }
</script>

<style scoped>

    .testimonial {
        margin-top: -10px; 
        padding-top: 20px; 
    }
    .testimonial .testimonial__arrows {
        z-index: 100;
    }

    .swiper-wrapper {
    display: flex;
    align-items: stretch; 
    }

    .review__single {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 300px; 
    box-sizing: border-box;
    padding: 20px;    
    }

    .review-content {
    flex-grow: 1;
    display: flex;
    align-items: center; 
    text-align: justify; 
    margin-bottom: 10px;
    }

    .review-head img,
    .review-meta img {
    max-width: 100%;
    height: auto;
    }

    .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto;
    min-height: 100%; 
    box-sizing: border-box;
    }
    
    .swiper-slide-active {
        opacity: 0.8;
    }

    .swiper-slide-prev,
    .swiper-slide-next {
        opacity: 0.5;
    }

@media (max-width: 767px) {
    .review__single {
        min-height: 250px;
    }
}
</style>