<template>
	<header :class="{ 'header-active': scrollPosition >= 100 }" class="header header-two">
	  <div class="container">
		<div class="row">
		  <div class="col-lg-12">
			<nav class="nav">
			  <div class="nav__content">
				<div class="nav__logo">
				  <router-link to="/">
					<img :src="require('@/assets/images/LOGO NEGATIVO SIN FONDO.png')" alt="Logo" />
				  </router-link>
				</div>
				<div class="nav__menu">
				  <ul class="nav__menu-items">
					<li class="nav__menu-item">
					  <router-link to="/" class="nav__menu-link hide-nav">{{ labelsState['start'] || 'Start' }}</router-link>
					</li>
					<li class="nav__menu-item">
					  <router-link :to="{ path: '/', hash: '#pricing' }" class="nav__menu-link hide-nav">
						{{ labelsState['plans'] || 'Plans' }}
					  </router-link>
					</li>
					<li class="nav__menu-item">
					  <router-link :to="{ path: '/', hash: '#about' }" class="nav__menu-link hide-nav">
						{{ labelsState['about_us'] || 'About Us' }}
					  </router-link>
					</li>
					<li class="nav__menu-item">
					  <router-link :to="{ path: '/', hash: '#FAQ' }" class="nav__menu-link hide-nav">
						{{ labelsState['contact_us'] || 'Contact Us' }}
					  </router-link>
					</li>
					<li class="nav__menu-item">
					  <router-link :to="{ path: '/', hash: '#CTA' }" class="nav__menu-link hide-nav">
						{{ labelsState['events'] || 'Events' }}
					  </router-link>
					</li>		
					<li class="nav__menu-item">
					  <a href="https://www.youtube.com/@NeuroQuantumArtificial/playlists" target="_blank" class="nav__menu-link hide-nav">
						{{ labelsState['tutorials'] || 'Tutorials' }}
					  </a>
					</li>				
					<li class="nav__menu-item">
						<a :href="loginUrl" target="_blank" class="btn btn--septenary">{{ labelsState['login'] || 'Login' }}</a>
					</li>

					<li class="nav__menu-item">	
					  <a :href="registerUrl" target="_blank" class="btn btn--septenary">{{ labelsState['register'] || 'Register' }}</a>
					</li>
		
				  </ul>
				  <div class="social">
					<a href="https://www.facebook.com/people/Neuro-Quantum-Artificial/61558761272414/" aria-label="social media">
					  <i class="fa-brands fa-facebook-f"></i>
					</a>
					<a href="https://x.com/NQArtificial" aria-label="social media">
					  <i class="fa-brands fa-twitter"></i>
					</a>
					<a href="https://www.linkedin.com/company/nqartificial/mycompany/" aria-label="social media">
					  <i class="fa-brands fa-linkedin-in"></i>
					</a>
					<a href="https://www.instagram.com/nqartificial/?igsh=bGs1bzd2dTQ1ZHA1" aria-label="social media">
					  <i class="fa-brands fa-instagram"></i>
					</a>
				  </div>
				</div>
				<div class="language-selector">
				  <button @click="toggleLanguageDropdown" class="btn btn--icon">
					<IconGlobe />
				  </button>
				  <div v-if="showLanguageDropdown" class="language-dropdown">
					<ul>
					  <li v-for="code in languageCodes" :key="code" @click="changeLanguage(code)">
						{{ code }}
					  </li>
					</ul>
				  </div>
				</div>
				<div class="nav__uncollapsed">
				  <div class="nav__uncollapsed-item d-none d-md-flex">
				  </div>
				  <button class="nav__bar d-block d-xl-none">
					<span class="icon-bar top-bar"></span>
					<span class="icon-bar middle-bar"></span>
					<span class="icon-bar bottom-bar"></span>
				  </button>
				</div>
			  </div>
			</nav>
		  </div>
		</div>
	  </div>
	  <div class="backdrop"></div>
	</header>
  </template>
  
  <script>
  import { useLabelsStore } from '@/stores/labels';
  import { useLanguageStore } from '@/stores/language';
  import { computed, reactive, onMounted, ref } from 'vue';
  import axios from 'axios';
  import IconGlobe from '@/components/icon/icon-globe.vue';
  
  export default {
	name: "Header2",
	components: {
	  IconGlobe,
	},
	setup() {
	  const labelsStore = useLabelsStore();
	  const languageStore = useLanguageStore();
  
	  const changeLanguage = (languageCode) => {
		labelsStore.fetchAndSetLabels(languageCode);
		showLanguageDropdown.value = false;
	  };
  
	  const labelsState = computed(() => labelsStore.labels);
	  const languageCodes = computed(() => languageStore.codes);
  
	  const showLanguageDropdown = ref(false);
  
	  const toggleLanguageDropdown = () => {
		showLanguageDropdown.value = !showLanguageDropdown.value;
	  };
  
	  const scrollPosition = ref(0);
  
	  const updateScroll = () => {
		scrollPosition.value = window.scrollY;
	  };

	  const loginUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-login`;
	  const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;
	  
  
	  const fetchData = async () => {
		try {
		  const response = await axios.get(`${process.env.VUE_APP_HOST_MANAGEMENT}/languages`, {
			withCredentials: true,
		  });
		  const languageCodes = response.data.map(lang => lang.code);
		  languageStore.setCodes(languageCodes);
	  
		  return languageCodes;
		} catch (error) {
		  console.error('Error fetching language codes:', error);
		  throw error;
		}
	  };
  
	  onMounted(() => {
		window.addEventListener("scroll", updateScroll);
		fetchData();
	  });
  
	  return {
		labelsState,
		languageCodes,
		changeLanguage,
		toggleLanguageDropdown,
		showLanguageDropdown,
		scrollPosition,
		loginUrl,
		registerUrl,
	  };
	},
  };
  </script>
  
  <style>
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	transition: all 0.3s ease;
	background-color: #111827; 
	padding: 0 50px; /* Agregar padding a los lados para crear espacio */
}

.header-active {
	background-color: #111827;
	padding: 20px 0;
}

.header-two .container {
	padding-left: 50px; /* Aumenta el padding para mover el contenido más hacia la derecha */
  padding-right: 15px;
	padding: 0 15px;
}

.nav {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.nav__content {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 24px;
	column-gap: 80px;
	min-width: 100%;
	background-color: #111827;
	justify-content: space-between;
	
}


.nav__menu {
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Asegura que todo esté alineado a la derecha */
  margin-left: -100px; /* Ajusta este valor para mover todo más a la derecha */
}

.nav__menu-items {
	display: flex;
	list-style: none;
	padding: 0;
	padding-left: 80px;
}

.nav__menu-item {
	margin-right: 20px;
}

.nav__menu-link {
	text-decoration: none;
	color: #fff;
	transition: color 0.3s ease;
	font-size: 14px;
}

.nav__menu-link:hover {
	color: #007bff;
}

.nav__logo {
  margin-left: auto; /* Ajusta este valor según lo que necesites */
}

.social {
	display: flex;
	align-items: center;
}

.social a {
	color: #fff; 
	margin-right: 10px;
	transition: color 0.3s ease;
}

.social a:hover {
	color: #007bff;
}

.language-selector {
	position: relative;
}

.btn--icon {
	background: none;
	border: none;
	cursor: pointer;
	color: #fff;
	font-size: 1.5rem;
}

.btn--icon:hover,
.btn--icon:focus {
	color: #fff;
	opacity: 0.7; 
}

.language-dropdown {
	position: absolute;
	top: 100%;
	right: 0;
	background-color: #111827;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 10px;
	width: 150px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.language-dropdown ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.language-dropdown li {
	padding: 10px;
	cursor: pointer;
	color: #fff;
}

.language-dropdown li:hover {
	background-image:linear-gradient(90deg, #00CC99 0%, #003366 95.62%);
	color: #fff;
}

.nav__uncollapsed {
	display: flex;
	align-items: center;
}

.nav__bar {
	background: none;
	border: none;
	cursor: pointer;
}

.icon-bar {
	display: block;
	width: 25px;
	height: 3px;
	background-color: #fff;
	margin: 5px 0;
	transition: all 0.3s ease;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 999;
}
</style>

