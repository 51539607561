<template>
    <section id="CTA" class="section cta-two pb-0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section__header wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <h4 class="h2">{{ labelsState['events'] || 'Events' }}</h4>
                    </div>
                    <div class="cta-two__inner bg-img wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <iframe v-if="sharebotChatUrl" :src="sharebotChatUrl" frameborder="0" width="100%" height="500px"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="anime anime--dark">
            <img :src="require('@/assets/images/anime-new-two.png')" alt="Image" class="one">
            <img :src="require('@/assets/images/anime-new-three.png')" alt="Image" class="two">
        </div>
        <div class="anime anime--light">
            <img :src="require('@/assets/images/anime-new.png')" alt="Image" class="one">
            <img :src="require('@/assets/images/anime-new.png')" alt="Image" class="two">
        </div>
    </section>
</template>

<script>
import { ref, onMounted,computed  } from 'vue';
import axios from 'axios';
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';

export default {
    name: "CTA",
    setup() {
        const sharebotChatUrl = ref('');
        const fetchDefaultValues = async () => {
            try {
                const response = await axios.get(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/default_parameter_cookie`, {
                    withCredentials: false,
                });
                console.log(response);
                const response_data = response.data;
                sharebotChatUrl.value = response_data['message']['event_url'];
            } catch (error) {
                console.error('Error fetching default values:', error);
            }
        };

        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();
        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };
        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);

        onMounted(() => {
            fetchDefaultValues();
        });

        return {
            sharebotChatUrl,
            labelsState,
            languageCodes,
            changeLanguage,
        };
    }
};
</script>

<style scoped>

.cta-two {
    margin-top: -100;  
    padding-top: 0;
    }
    .cta-two__inner {
        background-color: #111827;
        padding: 20px;  /* Ajusta el padding interno del contenedor para hacerlo más pequeño */
        margin: 0 auto; /* Centrar el contenedor si es necesario */
        /* max-width: 1000px; /* Ajusta el ancho máximo del contenedor */
        border-radius: 10px; /* Opcional: Añadir bordes redondeados */

    }
</style>