import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import Home2 from '@/pages/Home2.vue';
import About from '@/pages/About.vue';
import Error from '@/pages/Error.vue';
import Register from '@/pages/Register.vue';
import Signin from '@/pages/Signin.vue';
import TermsAndConditions from '@/pages/TermsAndConditions.vue';
import Policies from '@/pages/Policies.vue';
import DataTreatment from '@/pages/DataTreatment.vue';
import Blog from '@/pages/Blog.vue';
import Contact from '@/pages/Contact.vue';

Vue.use(Router);

let refCode = null;
async function handleRefCode() {
  const urlParams = new URLSearchParams(window.location.search);
  const urlRefCode = urlParams.get('refcode');
  console.log(urlRefCode)
  try {
    if (urlRefCode) {
      const response = await axios.get(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/refcode`, {
        params: { refcode: urlRefCode },
        withCredentials: true
      });

      if (response.status === 200) {
        refCode = urlRefCode;
      }
    } else {
      const response = await axios.get(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/refcode`, {
        withCredentials: true
      });

      if (response.status === 200 && response.data.refcode) {
        refCode = response.data.refcode;
      }
    }
  } catch (error) {
    console.error('Error fetching refcode:', error);
  }
}
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home Two',
      component: Home2,
    },
    {
      path: '/about',
      name: 'About Us',
      component: About,
    },
    {
      path: '/404',
      name: '404 Not Found',
      component: Error,
    },
    {
      path: '/TermsAndConditions',
      name: 'TermsAndConditions',
      component: TermsAndConditions,
    },
    {
      path: '/DataTreatment',
      name: 'DataTreatment',
      component: DataTreatment,
    },
    {
      path: '/Policies',
      name: 'Policies',
      component: Policies,
    },
    {
      path: '/blog',
      name: 'Blog',
      component: Blog,
    },
    {
      path: '/contact-us',
      name: 'Contact Us',
      component: Contact,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
router.beforeEach(async (to, from, next) => {
  await handleRefCode();
  document.title = to.meta.title || 'Neuro Quantum Artificial';
  next();
});
export { refCode };

export default router;